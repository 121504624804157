import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import {eye} from 'react-icons-kit/ionicons/eye';
import {facebookSquare} from 'react-icons-kit/fa/facebookSquare';
import {twitterSquare} from 'react-icons-kit/fa/twitterSquare';
import {linkedinSquare} from 'react-icons-kit/fa/linkedinSquare';
import {whatsapp} from 'react-icons-kit/fa/whatsapp';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
  LinkedinIcon,
  FacebookIcon,
} from "react-share";

    //URL patterns for Social media sites share functionalities
    // const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    // const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`;
    // const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
    // const whatsappUrl = `https://api.whatsapp.com/send?text=${url}`
const LeftBar = ({ sectionId, offset, onClick, text, redirecturl }) => {
  return (
    <div className="leftbar">
      <div className="smooth_scroll">
      <span className="btn_text">Share</span>
      <div className="Demo__some-network__custom-icon">
      <FacebookShareButton url={redirecturl} >
      <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      </div>
      <div className="Demo__some-network__custom-icon">
      <EmailShareButton url={redirecturl} >
      <EmailIcon size={32} round={true} />
      </EmailShareButton>
      </div>
      <div className="Demo__some-network__custom-icon">
      <LinkedinShareButton url={redirecturl} >
      <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
      </div>
      <div className="Demo__some-network__custom-icon">
      <TwitterShareButton url={redirecturl} >
      <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      </div>
      <div className="Demo__some-network__custom-icon">
      <WhatsappShareButton url={redirecturl} >
      <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      </div>
      {/* <a href={facebookUrl} target="_blank">
        <Icon
                 
                  size={25}
                  icon={facebookSquare}
                />
        </a>
        <a href={twitterUrl} target="_blank">
        <Icon
                  size={25}
                  icon={twitterSquare}
                />
        </a>
        <a href={linkedinUrl} target="_blank">
        <Icon
                 
                  size={25}
                  icon={linkedinSquare}
                />
        </a>
        <a href={whatsappUrl} target="_blank">
        <Icon
                 
                  size={25}
                  icon={whatsapp}
                />
        </a> */}
        
        </div>
    </div>
  );
};

export default LeftBar;
