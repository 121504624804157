import React, { useState, useEffect, Fragment } from 'react';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { angleRight } from 'react-icons-kit/fa/angleRight';
import Container from 'common/src/components/UI/Container';
import BlogPost from 'common/src/components/BlogPost';
import SectionHeading from '../SectionHeading';
import SectionWrapper, { ContentWrapper } from './newsFeed.style';
import firestore from "../../../firestore";
import  firebase from "firebase/app";
import "firebase/firestore";
const NewsFeed = () => {
  const [state, setState] = useState({
    des:[]
  });
useEffect(() => {
  // if (!firestore) return;
  async function fetchData(){
    const response = await firebase.firestore().collection("BlogsDetails").orderBy("timestamp","desc").limit(3)
    .onSnapshot(function(querySnapshot) {
        var cities = [];
        querySnapshot.forEach(function(doc) {
          var obj = doc.data()
          obj.id = doc.id
            cities.push(obj);
            setState({
              ...state,
              des: cities,
            });
        });
     
    });
    
  }
  fetchData();
 },[]);

var responses  = state.des;
  return (
    <SectionWrapper id="news">
      <Container>
        <Zoom>
          <SectionHeading
           
            title="Latest Stories"
          />
        </Zoom>
        <ContentWrapper>
          {state.des.map(news => (
            <Fade key={news.id} up delay={100 * news.id}>
              <BlogPost 
              id ={news.id}
                key={`blog__post-key${news.id}`}
                thumbUrl={news.BannerImg? news.BannerImg :""}
                title={news.Title}
                excerpt={`${news.ArticleCopy.substring(0,80)}...`}
                link={
                  <Fragment>
                  <Link to={`/blogdetails/${news.id}`}
                    state={{  blogid: news.id }} className="learn__more-btn">
                      <span className="hyphen" />
                      <span className="btn_text">Read More</span>
                    </Link>
                  </Fragment>
                }
              />
            </Fade>
          ))}
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default NewsFeed;
