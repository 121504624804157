import styled, { keyframes } from 'styled-components';
import { themeGet } from 'styled-system';
const shake = keyframes`
0% {
  transform: translateX(-1px);
}
50% {
  transform: translateX(5px);
}
100% {
  transform: translateX(0);
}
`;
const SectionWrapper = styled.section`
  padding: 70px 0;
  @media only screen and (max-width: 767px) {
    padding: 10px 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .react-reveal {
    flex: 0 0 calc(33.3333% - 20px);
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
      margin-right: 0;
      margin-top: 10px;
    }
  }
  .blog_post {
    img {
      max-width: 100%;
      border-radius: 10px;
    }
    p {
      font-size: 15px;
      line-height: 32px;
      color: ${themeGet('colors.text')};
    }
    .title {
      color: ${themeGet('colors.heading', '#060F1E')};
      font-weight:500;
      &:hover {
        color:#1A73E8;
      }
    }
    .excerptLink {
      color: #3183ff;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      i {
        margin-left: 2px;
        transition: 0.3s ease 0s;
      }
      &:hover i {
        margin-left: 5px;
      }
    }
    .learn__more-btn {
      margin-top: 40px;
      @media only screen and (max-width: 1440px) {
        margin-top: 25px;
      }
      @media only screen and (max-width: 1360px) {
        margin-top: 20px;
      }
      
      &::before {
        background-color: #fffa9a !important;
      }
     

    }
    .learn__more-btn {
      display: inline-flex;
      align-items: center;
      font-family: 'Open Sans', sans-serif;
      color: ${themeGet('colors.heading', '#060F1E')};
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0;
      height: 50px;
      min-width: 151px;
      padding: 0 20px;
      position: relative;
      @media only screen and (max-width: 1360px) {
        font-size: 13px;
      }
  
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 40px;
        width: 72px;
        background-color: ${themeGet('colors.primary', '#FCF22B')};
        transition: all 0.3s ease-out;
      }
  
      .btn_text {
        z-index: 1;
      }
  
      .hyphen {
        width: 20px;
        height: 2px;
        margin-right: 9px;
        background-color: ${themeGet('colors.heading', '#060F1E')};
        transform-origin: 50% 50%;
        z-index: 1;
      }
  
      &:hover {
        &::before {
          width: 100%;
        }
  
        .hyphen {
          animation: ${shake} 1s linear;
        }
      }
    }
  }
`;

export default SectionWrapper;
