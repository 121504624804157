import React, { useState,Fragment, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import illustration from 'common/src/assets/image/app/institute1.jpg';
import { Icon } from 'react-icons-kit';
import {eye} from 'react-icons-kit/ionicons/eye';
import LeftBar from './leftBar';
import firestore from "../../../firestore";
import  firebase from "firebase/app";
import "firebase/firestore";
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
  HighlightedText,
  Description,
  Author
} from './bannerSection.style';
import {Texting} from './post.style'

// const [state, setState] = useState({
//  data:{},
 
// });


const BannerSection = ({blogid , url }) => {
  // var blogid = location.state.blogid
        const [state, setState] = useState({
        des:[],
        id:''
      });
    useEffect(() => {
      // if (!firestore) return;
      async function fetchData(){
        //console.log(blogid)
        var id = blogid
        const response = await firebase.firestore().collection("BlogsDetails").doc(id).get().then((doc) => {
          // console.log('User:', doc.data());
          setState({
                ...state,
                des: doc.data(),
              });
        });
        
      }
      fetchData();
     },[blogid]);
    
    var {AuthorJob,ArticleDate, views, Authorcompany,BannerImg, ArticleCopy, ArticleCategory, AuthorName, timestamp, ArticleSummary, Articlepost, Title}  = state.des;
    //  console.log(ArticleDate);
    var d = ArticleDate && ArticleDate.toDate();
    var ds = ArticleDate && d.toDateString('MM/dd/yy');
    return (
    <BannerWrapper>
      <ContentWrapper>
        <TextArea>
        <HighlightedText >
          <strong>{ArticleCategory}</strong> 
            
          </HighlightedText>
          {/* {state.map((item, index) => {
            <p>{item.des.AuthorName}</p>
          })
        } */}
          <Heading
            content={Title}
          />
          {/* <Link className="learn__more-btn" to="/charity">
            <span className="hyphen" />
            <span className="btn_text">Explore Our Project</span>
          </Link> */}
        </TextArea>
        <ImageArea>
          
        <Image src={BannerImg} alt=""

                    className="slide_image"
                  />
               
        </ImageArea>
        <Description>
          <Author>
          <Heading as="h4"
            content={AuthorName}
          />
          <Text
            content={
              <Fragment>
                {AuthorJob} - {Authorcompany}
              </Fragment>
            }
          />
          <Text className="date"
            content={ds}
          />
          {/* <Text content={state.des} /> */}
          <span style={{ color: '#93a2ab' , fontSize:'12px'}}>
           <Icon
                  style={{ color: '#93a2ab' }}
                  className="bar"
                  size={20}
                  icon={eye}
                /> {views} </span>
          </Author>
        <Texting>
          {/* <div dangerouslySetInnerHTML={{__html: something}}
            
          /> */}
          <div className="ck-content" dangerouslySetInnerHTML={{__html:Articlepost}} >
        
          </div>      
    
        </Texting>
        </Description>
      </ContentWrapper>
      {/* <LeftBar text="SCROLL DOWN" offset={81}  offset1={91} offset2={101} linkinsta="https://instagram.com" linkfb="https://instagram.com" linkyt="https://instagram.com"/> */}
      <LeftBar text="SCROLL DOWN" offset={181} sectionId="#feature" redirecturl={url} />
    </BannerWrapper>
  );
};

export default BannerSection;
